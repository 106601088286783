import classNames from 'classnames';

type SquareLinkProps = {
  href?: string;
  name: string;
  children: React.ReactNode;
};

export default function SquareLink({
  href = '',
  name,
  children,
}: SquareLinkProps) {
  return (
    <div className='font-hass text-base font-semibold leading-tight tracking-wide lg:text-xl'>
      <p>{name}</p>
      <a
        href={href}
        className={classNames(
          'whitespace-nowrap',
          'mt-5 lg:mt-12',
          'relative flex items-center justify-center gap-2.5 rounded p-5',
          'bg-[#0C0B21] text-xl text-white dark:bg-[#171634]',
          'h-[140px]',
          'hover:[box-shadow:12px_19px_31px_rgba(1,0,23,0.28)]',
          'hover:[background:linear-gradient(90deg,#2B4A9F_0%,#44A474_100%)]'
        )}
      >
        {children}
      </a>
    </div>
  );
}
