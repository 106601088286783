export default function ChevronRightIcon() {
  return (
    <svg
      width='10'
      height='9'
      viewBox='0 0 10 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 4.77002H1'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 7.77002L9 4.77002L6 1.77002'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
