import classNames from 'classnames';
import {
  domAnimation,
  LazyMotion,
  m,
  motionValue,
  useScroll,
  useSpring,
  useTransform,
} from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { isChrome, isDesktop } from 'react-device-detect';

import useMediaQuery from '@/hooks/useMediaQuery';
import useTheme from '@/hooks/useTheme';

import AppPrototype from './components/AppPrototype';
import BlurryShadow from './components/BlurryShadow';
import ChromePython from './components/ChromePython';
import OptimizedPython from './components/OptimizedPython';
import { HomepageProps } from '../helpers/types';

export default function Python({ moveLess }: HomepageProps) {
  const ref = useRef(null);
  const { isLight } = useTheme();

  const { scrollYProgress } = useScroll({
      target: ref,
      offset: ['.4 end', 'end end'],
    }),
    SPRING_CONFIG = {
      stiffness: 500,
      damping: 50,
    };

  const x = useSpring(
      useTransform(
        moveLess ? motionValue(1) : scrollYProgress,
        [0, 1],
        [-200, 0]
      ),
      SPRING_CONFIG
    ),
    opacity = useSpring(
      useTransform(scrollYProgress, [0, 0.3], [0.3, 1]),
      SPRING_CONFIG
    );

  const largeScreenOnly = useMediaQuery('(min-width: 768px)');

  const [showOriginal, toggle] = useState(false);

  useEffect(() => {
    toggle(isDesktop && isChrome);
  }, []);

  return (
    <section className='relative py-20'>
      <BlurryShadow
        className={classNames(
          'translate-x-[0%] -translate-y-[55%]',
          'absolute top-0 right-0 -z-20',
          'h-[1512.96px] w-[687.4px]'
        )}
        background={
          isLight
            ? 'radial-gradient(50% 50% at 50% 50%, rgba(69, 163, 115, 0.6) 0%, rgba(255, 255, 255, 0) 63.54%)'
            : 'radial-gradient(50% 50% at 50% 50%, rgba(29, 113, 113, 0.48) 0%, rgba(21, 61, 74, 0) 63.54%)'
        }
        rotateDeg={20}
      />
      <BlurryShadow
        className={classNames(
          'absolute bottom-0 right-0 -z-20',
          'translate-x-[70%] translate-y-[50%]',
          'h-[1192.01px] w-[541.58px]'
        )}
        background={
          isLight
            ? 'radial-gradient(50% 50% at 50% 50%, rgba(54, 49, 198, 0.4) 0%, rgba(255, 255, 255, 0) 63.54%)'
            : 'radial-gradient(50% 50% at 50% 50%, rgba(54, 49, 198, 0.4) 0%, rgba(56, 51, 204, 0) 63.54%)'
        }
        rotateDeg={20}
      />
      <div ref={ref} className='relative'>
        <div className='container'>
          <LazyMotion features={domAnimation}>
            <m.div
              style={{ x, opacity }}
              className={classNames('pl-6', !largeScreenOnly && '-mx-3', {
                'max-w-[500px]': !largeScreenOnly,
              })}
            >
              <AppPrototype mobile={!largeScreenOnly} />
            </m.div>
          </LazyMotion>
        </div>
        <div className='pointer-events-none absolute -top-28 left-0 flex w-screen cursor-none justify-center xs:-top-10 sm:top-0 md:-top-5'>
          <div className='-ml-4'>
            {showOriginal ? (
              <ChromePython
                className='w-[105vw] -translate-x-14 -rotate-[20deg] scale-[1.5] md:translate-x-0 md:rotate-0 md:scale-100'
                scrollYProgress={scrollYProgress}
                dontAnimate={moveLess}
                springConfig={SPRING_CONFIG}
              />
            ) : (
              <OptimizedPython
                className='w-[105vw] -translate-x-14 -rotate-[20deg] scale-[1.5] md:translate-x-0 md:rotate-0 md:scale-100'
                scrollYProgress={scrollYProgress}
                dontAnimate={moveLess}
                springConfig={SPRING_CONFIG}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
