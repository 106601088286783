import { useTranslation } from 'next-i18next';
import Skeleton from 'react-loading-skeleton';

import WrappedImg from '@/components/WrappedImg';

import { useAppSelector } from '@/store/index';
import { indexerSelector } from '@/store/indexer';
import { landingSelector } from '@/store/landing';

import { links } from '@/config/envVars';
import { nFormatter } from '@/utils/helpers';

const useOpportunityData = () => {
  const { t } = useTranslation('agora');

  const { isLoadingFirstInfo } = useAppSelector(indexerSelector);
  const { totalValueLockedUSD } = useAppSelector(landingSelector);

  return [
    {
      url: `${links.mainnetURL}/lend`,
      textContent: [
        'Hatom Protocol',
        isLoadingFirstInfo ? (
          <Skeleton width={90} />
        ) : (
          t('x-tvl', { amount: `$${nFormatter(totalValueLockedUSD)}` })
        ),
        ...t('o1', { joinArrays: '|' }).split('|'),
      ],
      theme: [
        '#7343FB',
        'linear-gradient(99.1deg, #4927AB 1.6%, #0B0636 98.03%)',
        '#7857E3',
      ],
      illustration: (
        <div className='absolute -bottom-4 right-0 md:-bottom-5'>
          <WrappedImg className='h-[232.52px]' src='https://cdn.app.hatom.com/images/agora/o1.png' />
        </div>
      ),
    },
    {
      url: links.hatomLabsURL,
      textContent: ['Hatom Labs', ...t('o2', { joinArrays: '|' }).split('|')],
      theme: [
        '#6A6A6A',
        'linear-gradient(102.66deg, #343434 -2.34%, #000000 99.38%)',
        '#656565',
      ],
      illustration: (
        <div className='absolute -bottom-3.5 -right-1.5 md:-bottom-5'>
          <WrappedImg className='h-[232.52px]' src='https://cdn.app.hatom.com/images/agora/o2.png' />
        </div>
      ),
      className: {
        p: '!max-w-[200px] xs:!max-w-[250px] sm:!max-w-[218px] xl:max-w-[250px]',
      },
    },
    {
      url: links.esdtURL,
      textContent: ['Esdt', ...t('o3', { joinArrays: '|' }).split('|')],
      theme: [
        '#9853AB',
        'linear-gradient(99.78deg, #552661 3.76%, #3C1548 95.04%)',
        '#A667B8',
      ],
      illustration: (
        <div className='absolute -bottom-0 right-0'>
          <WrappedImg className='h-[208.39px]' src='https://cdn.app.hatom.com/images/agora/o3.png' />
        </div>
      ),
    },
    {
      url: 'https://www.ush.io/ ',
      textContent: ['HatomUSD', ...t('o4', { joinArrays: '|' }).split('|')],
      theme: [
        '#017AE8',
        'linear-gradient(107.12deg, #0E20AE -9.02%, #0A0F50 61.78%)',
        '#4857FD',
      ],
      illustration: (
        <div className='absolute -bottom-3.5 -right-2 md:-bottom-4'>
          <WrappedImg className='h-[237.18px]' src='https://cdn.app.hatom.com/images/agora/o4.png' />
        </div>
      ),
    },
    {
      url: '/lend',
      textContent: ['HTM Booster', ...t('o5', { joinArrays: '|' }).split('|')],
      theme: [
        '#642C42',
        '#642C42 linear-gradient(100.82deg, #5F2B40 4.01%, #3C1B28 97.23%)',
        '#A84E71',
      ],
      illustration: (
        <div className='absolute -bottom-0.5 -right-8'>
          <WrappedImg className='h-[200px]' src='https://cdn.app.hatom.com/images/agora/o5.png' />
        </div>
      ),
    },
    {
      url: 'https://www.syfy.io/',
      textContent: ['Syfy', ...t('o6', { joinArrays: '|' }).split('|')],
      theme: [
        '#2B2962',
        '#2B2962 linear-gradient(101.83deg, #2C126C 5.07%, #1B0A47 51.49%)',
        '#5047BE',
      ],
      illustration: (
        <div className='absolute -bottom-2 -right-2.5 md:-bottom-0'>
          <WrappedImg className='h-[217.43px]' src='https://cdn.app.hatom.com/images/agora/o6.png' />
        </div>
      ),
      className: { p: 'w-[210px]' },
    },
    {
      textContent: [
        'Hatom Treasury',
        ...t('o7', { joinArrays: '|' }).split('|'),
      ],
      theme: [
        '#3E986F',
        'linear-gradient(99.2deg, #215552 7.8%, #1B3335 62.79%)',
        '#599B87',
      ],
      illustration: (
        <div className='absolute -bottom-0 -right-3'>
          <WrappedImg className='h-[226.54px]' src='https://cdn.app.hatom.com/images/agora/o7.png' />
        </div>
      ),
      className: { p: 'xl:max-w-[240px]' },
    },
    {
      textContent: ['Soul', ...t('o8', { joinArrays: '|' }).split('|')],
      theme: [
        '#5169B1',
        'linear-gradient(101.41deg, #3D56A3 3.43%, #223672 59.81%)',
        '#6F87CE',
      ],
      illustration: (
        <div className='absolute -bottom-1 right-0'>
          <WrappedImg className='h-[220.93px]' src='https://cdn.app.hatom.com/images/agora/o8.png' />
        </div>
      ),
    },
  ];
};

export default useOpportunityData;
