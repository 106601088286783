import classNames from 'classnames';
import {
    AnimatePresence,
    domAnimation,
    LazyMotion,
    m,
    motionValue,
    useScroll,
    useSpring,
    useTransform,
} from 'framer-motion';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect, useRef } from 'react';

import useTheme from '@/hooks/useTheme';
import useToggle from '@/hooks/useToggle';

import { SOCIAL_MEDIA } from '@/components/DashboardFooter';

import { HomepageProps } from '../helpers/types';
import useCanAnimate from '../helpers/useCanAnimate';
import useOpportunityData from '../helpers/useOpportunityData';
import BlurryShadow from '../PythonPath/components/BlurryShadow';
import Opportunity from './components/Opportunity';
import OpportunityBed from './components/OpportunityBed';

export default function Opportunities({ moveLess }: HomepageProps) {
  const ref = useRef(null);
  const { t } = useTranslation('agora');

  const OPPORTUNITIES = useOpportunityData();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  });

  const COLS_COUNT = 3;

  const animate = useCanAnimate();

  const calculation = (c = 0, r = 0) => {
    const percentage = 1 / COLS_COUNT;
    const s = percentage * r;
    const e = percentage * (r + 1);
    const increment = COLS_COUNT * 0.03 * (COLS_COUNT - c);
    const decrement = COLS_COUNT * 0.03 * c;

    const ordinary = [s + increment, e - decrement];
    const oneDirection = [s + decrement, e - increment];

    return [animate ? ordinary : oneDirection, [-200, 0]];
  };

  const [showViewAllButton, , setButtonDisplay] = useToggle();
  const [loadFew, toggle] = useToggle(true);

  useEffect(() => {
    setButtonDisplay(!animate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animate]);

  const springConfig = {
    stiffness: 500,
    damping: 80,
  };

  const { isLight } = useTheme();

  function getOffset(el: any) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  const thirdCard = useRef(null);

  function handleButtonClick() {
    if (!loadFew)
      setTimeout(
        () => window.scrollTo(0, getOffset(thirdCard?.current).top - 30),
        0
      );
    toggle();
  }

  return (
    <section className='container-lg relative'>
      <BlurryShadow
        className={classNames(
          '-translate-x-[50%] -translate-y-[10%]',
          'absolute top-0 left-0 -z-20',
          'h-[840.63px] w-[381.93px]'
        )}
        background={
          isLight
            ? 'radial-gradient(48.4% 48.4% at 50% 50%, rgba(54, 49, 198, 0.4) 0%, rgba(255, 255, 255, 0) 83.61%)'
            : 'radial-gradient(48.4% 48.4% at 50% 50%, rgba(54, 49, 198, 0.4) 0%, rgba(56, 51, 204, 0) 83.61%)'
        }
        rotateDeg={-22}
      />
      <BlurryShadow
        className={classNames(
          '-translate-x-[80%] translate-y-[10%]',
          'absolute top-0 left-0 -z-20',
          'h-[840.63px] w-[381.93px]'
        )}
        background={
          isLight
            ? 'radial-gradient(50% 50% at 50% 50%, rgba(168, 49, 198, 0.2) 0%, rgba(118, 51, 204, 0) 63.54%)'
            : 'radial-gradient(50% 50% at 50% 50%, rgba(168, 49, 198, 0.2) 0%, rgba(118, 51, 204, 0) 63.54%)'
        }
        rotateDeg={-22}
      />
      <h2 className='font-hass text-[20px] capitalize'>
        {t('explore-opportunities')}
      </h2>
      <LazyMotion features={domAnimation}>
        <m.div
          ref={ref}
          className='mt-10 grid gap-8 gap-y-10 sm:grid-cols-2 sm:gap-4 md:gap-8 md:gap-y-10 lg:grid-cols-3 lg:gap-4 xl:gap-8 xl:gap-y-10'
        >
          {OPPORTUNITIES.map((e, i) => {
            const c = i % COLS_COUNT;
            let r = 0;
            if (i >= COLS_COUNT) r = (i / COLS_COUNT) | 0;

            const hidden = showViewAllButton && loadFew && i >= 3;
            const style = {
              x: useSpring(
                useTransform(
                  moveLess ? motionValue(1) : scrollYProgress,
                  ...calculation(c, r)
                ),
                springConfig
              ),
              zIndex: 10 + c * -1,
            };

            return (
              <AnimatePresence key={i}>
                {!hidden && (
                  <m.div ref={i === 2 ? thirdCard : null} style={style}>
                    <Opportunity {...e} />
                  </m.div>
                )}
              </AnimatePresence>
            );
          })}
          <OpportunityBed
            forceH={185}
            background='#1E1D31'
            borderColor='#333153'
          >
            <h3 className='text-[16px] tracking-wide sm:text-[20px]'>
              {t('connect-with-us')}
            </h3>
            <div className='mt-4 flex h-12 justify-center'>
              {[...SOCIAL_MEDIA]
                .reverse()
                .sort((a) => (a.name === 'medium' ? -1 : 0))
                .map((e, i) => (
                  <div className='flex' key={i}>
                    <div className='transition-transform hover:-translate-y-1'>
                      <Link legacyBehavior href={e.url} target='_blank'>
                        <a>
                          <img
                            className='w-[48px] sm:w-[56px]'
                            src={`https://cdn.app.hatom.com/images/agora/${e.name}.svg`}
                            alt=''
                          />
                        </a>
                      </Link>
                    </div>
                    {i != SOCIAL_MEDIA.length - 1 && (
                      <div className='mx-4 w-px bg-[#302F4E]'></div>
                    )}
                  </div>
                ))}
            </div>
          </OpportunityBed>
        </m.div>
      </LazyMotion>
      {showViewAllButton && (
        <button
          onClick={handleButtonClick}
          className='-m-2 mx-auto mt-8 block p-2 text-sm uppercase'
        >
          {loadFew ? 'Show More' : 'Show Less'}
        </button>
      )}
    </section>
  );
}
