import classNames from 'classnames';
import {
    domAnimation,
    LazyMotion,
    m,
    useAnimationControls,
} from 'framer-motion';
import { Trans } from 'next-i18next';
import { useContext, useEffect } from 'react';

import MultiversXLogo from '@/components/MultiversXLogo';
import WrappedImg from '@/components/WrappedImg';

import { LoaderContext } from '@/contexts/LoaderContextProvider';

import { HomepageProps } from '../helpers/types';

export default function BigCenteredTitle({ moveLess }: HomepageProps) {
  const controls = useAnimationControls();
  const { open } = useContext(LoaderContext);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        controls.start(() => ({
          x: 0,
          opacity: 1,
          transition: { ease: 'easeInOut', duration: 0.5 },
        }));
      }, 300);
    }

    return controls.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const initial = (reversed?: boolean) => ({
    x: -40 * (reversed ? -1 : 1),
    opacity: 0,
  });

  return (
    <LazyMotion features={domAnimation}>
      <section className='container-lg relative flex flex-col items-center gap-2 py-14 lg:gap-7 lg:py-20'>
        <h1 className='text-center text-[6.6vw] lg:text-[73px] lg:leading-[1.02]'>
          <Trans
            i18nKey='page-heading'
            components={[
              <m.span
                custom={1}
                {...(moveLess ? {} : { initial: initial(), animate: controls })}
              />,
              <m.div
                {...(moveLess
                  ? {}
                  : { initial: initial(true), animate: controls })}
                className='gradient-text'
              />,
              <span className='font-hass font-bold' />,
            ]}
            ns='agora'
          />
        </h1>
        <m.div {...(moveLess ? {} : { initial: initial(), animate: controls })}>
          <MultiversXLogo
            width={380}
            height={56}
            color='currentColor'
            className='h-auto w-[38vw] md:w-auto'
          />
        </m.div>
        <WrappedImg
          className={classNames(
            'absolute -left-3 top-[40%] h-[166.69px] scale-[.35] sm:scale-[.5] md:top-[45%] md:left-4 md:scale-[.7] lg:top-[54%] lg:scale-100',
            'xl:-left-3 xl:top-[30%] xl:scale-100'
          )}
          src='https://cdn.app.hatom.com/images/agora/spiral-glass.png'
        />
        <WrappedImg
          className={classNames(
            'absolute -right-10 bottom-0 h-[140.55px] translate-y-1/4 scale-[.3] sm:scale-[.45] md:-right-0 md:bottom-3 md:scale-[.55]',
            'lg:right-[64px] lg:bottom-0 lg:translate-y-[56%] lg:scale-100'
          )}
          src='https://cdn.app.hatom.com/images/agora/chain-glass.png'
        />
      </section>
    </LazyMotion>
  );
}
