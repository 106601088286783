import { useTranslation } from 'next-i18next';
import { PlacementType } from 'react-laag/dist/PlacementType';

type HintProps = {
  alwaysOpen?: boolean;
  unvisible?: boolean;
  placement?: PlacementType;
  x?: number;
  y?: number;
};

export default function useHintProps({
  alwaysOpen = false,
  unvisible = false,
  placement = 'top-end',
  x = 0,
  y = 0,
}: HintProps) {
  const { t } = useTranslation();

  return {
    block: true,
    alwaysOpen,
    content: (
      <span className='font-semibold leading-none tracking-wide'>
        {t('coming-soon')}
      </span>
    ),
    layerStyle: {
      display: 'inline-flex',
      padding: '6px',
      borderRadius: 6,
      translate: `-${x}px ${y}px`,
    },
    unvisible,
    backgroundColor: '#030303',
    placement,
    arrow: false,
    offset: 0,
  };
}
