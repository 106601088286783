import classNames from 'classnames';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import CustomLink from '@/components/CustomLink';
import Hint from '@/components/Hint';

import SimpleButton from './SimpleButton';
import useHintProps from '../../helpers/useHintProps';

{
  /* BubbleCard Props type */
}
type BubbleCardProps = {
  textContent?: any;
  illustration?: any;
  lens?: any;
  tiny?: boolean;
  live?: boolean;
  hSuffix?: React.ReactNode;
  links?: any[];
};

export default function BubbleCard({
  textContent,
  illustration,
  lens,
  tiny,
  live,
  hSuffix,
  links = [] as any[],
}: BubbleCardProps) {
  const [t1, t2, t3] = textContent;
  const hintProps = useHintProps({
    unvisible: !!live,
    x: tiny ? 40 : 120,
    y: tiny ? 40 : 40,
  });

  return (
    <Hint {...hintProps}>
      <div className={classNames('transition-transform hover:scale-[1.02]')}>
        <div
          className={classNames(
            tiny ? 'lg:max-h-[276px]' : 'lg:max-h-[329px]',
            'relative flex flex-col p-5 pr-2.5 lg:p-7 xl:p-10 xl:pr-5',
            'bg-white/60 backdrop-blur-[2.5px] dark:bg-[#1B1A37]/80',
            'border-2 border-[#EBF0FC] dark:border-[#2E2C51]',
            'rounded-3xl md:rounded-[36px]',
            tiny ? 'lg:rounded-[40px]' : 'lg:rounded-[48px]'
          )}
        >
          <p className='font-hass text-xs font-bold leading-tight tracking-wide text-[#838A9B] dark:text-[#7D79AB] xs:text-sm'>
            {t1}
          </p>
          <h3
            className={classNames(
              'text-xl xs:text-[28px] sm:text-xl md:text-[28px]',
              tiny ? 'xl:text-[28px]' : 'xl:text-[42px]',
              '!leading-tight',
              'mt-4'
            )}
            dangerouslySetInnerHTML={{
              __html: t2 + ' ' + ReactDOMServer.renderToStaticMarkup(hSuffix),
            }}
          ></h3>
          {t3 && (
            <div className='mt-2 max-w-[60%] font-hass text-xs tracking-wide text-[#575757] dark:text-[#625E8F] xs:text-sm lg:max-w-[100%]'>
              {t3}
            </div>
          )}
          <div
            className={classNames(
              'flex gap-2 lg:gap-3 xl:gap-5',
              !t3 ? 'mt-10' : 'mt-8'
            )}
          >
            {links.map((e) => (
              <CustomLink href={e.url} external={e.external} key={e.name}>
                <SimpleButton link={e.arrow} secondary={!e.primary}>
                  {e.name}
                </SimpleButton>
              </CustomLink>
            ))}
          </div>
          {lens && (
            <div className='absolute top-5 right-5 lg:top-10 lg:right-10'>
              <div className='grid h-[50px] w-[50px] origin-top-right scale-[.7] place-items-center rounded-full bg-[#F4F5FA] dark:bg-[#2B284B] lg:scale-100'>
                {lens}
              </div>
            </div>
          )}

          {React.cloneElement(illustration, {
            className: classNames(
              illustration.props.className,
              '-z-10 origin-right lg:scale-100',
              {
                'scale-75': !tiny,
                'scale-90': tiny,
              }
            ),
          })}
        </div>
      </div>
    </Hint>
  );
}
