import classNames from 'classnames';

type OpportunityBedProps = {
  children: React.ReactNode;
  background: string;
  borderColor: string;
  forceH?: number;
};

export default function OpportunityBed({
  children,
  background,
  borderColor,
  forceH,
}: OpportunityBedProps) {
  return (
    <div
      className={classNames(
        'relative flex flex-col gap-2.5 rounded-xl p-10 px-5',
        'text-white',
        'h-[158px] w-full xs:h-[185px]',
        'border-2',
        'font-hass text-sm font-semibold leading-tight tracking-wide'
      )}
      style={{
        height: forceH,
        background,
        borderColor,
      }}
    >
      {children}
    </div>
  );
}
