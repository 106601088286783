import { useTranslation } from 'next-i18next';

import { links } from '@/config/envVars';

const useLinksData = () => {
  const { t } = useTranslation('agora');
  return [
    {
      gropuName: t('join-us'),
      slug: 'careers',
      body: t('careers'),
      url: 'https://jobs.syfy.io/companies/hatom-labs#content',
    },
    {
      gropuName: t('bug-bounty'),
      slug: 'immunefi-program',
      body: t('x-program', { protocol: 'Immunefi' }),
      url: 'https://docs.hatom.com/getting-started/security/bug-bounty',
    },
    {
      gropuName: t('translation:security'),
      slug: 'audits',
      body: t('audits'),
      url: 'https://docs.hatom.com/getting-started/security/security-reports',
    },
    {
      gropuName: t('translation:learn-more'),
      slug: 'documentation',
      body: t('translation:documentation'),
      url: links.docURL,
    },
  ];
};

export default useLinksData;
