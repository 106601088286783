import AnimatedNumber from 'animated-number-react';
import classNames from 'classnames';
import {
    domAnimation,
    LazyMotion,
    m,
    motionValue,
    useInView,
    useScroll,
    useSpring,
    useTransform,
} from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

import useTheme from '@/hooks/useTheme';

import WrappedImg from '@/components/WrappedImg';

import { useAppSelector } from '@/store/index';
import { indexerSelector } from '@/store/indexer';
import { landingSelector } from '@/store/landing';

import { nFormatter } from '@/utils/helpers';

import { HomepageProps } from '../helpers/types';

export default function MarketSummary({ moveLess }: HomepageProps) {
  const { isDark } = useTheme();
  const towerURL = `https://cdn.app.hatom.com/images/agora/tower${isDark ? '-night' : ''}.svg`;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { t } = useTranslation('agora');
  const { totalSupplyUSD, totalBorrowUSD } = useAppSelector(landingSelector);

  const towerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: towerRef,
    offset: ['start end', '.8 end'],
  });
  const SPRING_CONFIG = {
    stiffness: 400,
    damping: 80,
  };

  const y = useSpring(
      useTransform(
        moveLess ? motionValue(1) : scrollYProgress,
        [0, 1],
        [350, 0]
      ),
      SPRING_CONFIG
    ),
    opacity = useSpring(scrollYProgress, SPRING_CONFIG);

  const { isLoadingFirstInfo } = useAppSelector(indexerSelector);

  return (
    <section className='pt-[100px] pb-10 md:-mb-8 md:py-0  xl:my-0 xl:pt-[100px] xl:pb-10'>
      <div ref={ref} className='relative mx-auto max-w-[1512px]'>
        <div className='relative inset-0 z-10 md:absolute'>
          <div className='container-lg relative font-hass md:mt-[108px]'>
            <div className='sm:w-2/3 lg:w-[50.601vw] lg:max-w-[800px] xl:w-[800px]'>
              <p className='text-[16px] tracking-wide md:text-[20px] lg:text-[23px]'>
                {t('tower-desc')}
              </p>
              <div className='mt-[60px] flex flex-col justify-between gap-[60px] whitespace-nowrap md:mx-11 md:mt-[108px] md:flex-row'>
                <div className='inline-flex flex-col gap-2 leading-tight tracking-wide md:items-center lg:gap-2.5'>
                  <div className='text-sm md:text-base lg:text-xl'>
                    {t('total-assets-supplied')}
                  </div>
                  <p className='gradient-text text-[28px] font-normal [--gradient:linear-gradient(90.31deg,#7448D1_30.37%,#173EC7_94.8%)] md:text-[30px] lg:text-[44px]'>
                    {isLoadingFirstInfo ? (
                      <Skeleton inline width={148} />
                    ) : (
                      <AnimatedNumber
                        duration={2000}
                        value={isInView ? totalSupplyUSD : 0}
                        formatValue={(value: any) => `$${nFormatter(value)}`}
                      />
                    )}
                  </p>
                </div>
                <div className='inline-flex flex-col gap-2 leading-tight tracking-wide md:-mt-9 md:items-center lg:gap-2.5'>
                  <div className='text-sm md:text-base lg:text-xl'>
                    {t('total-assets-borrowed')}
                  </div>
                  <p className='gradient-text text-[28px] font-normal [--gradient:linear-gradient(90deg,#2B4A9F_0%,#44A474_100%)] md:text-[30px] lg:text-[44px]'>
                    {isLoadingFirstInfo ? (
                      <Skeleton inline width={148} />
                    ) : (
                      <AnimatedNumber
                        duration={2000}
                        value={isInView ? totalBorrowUSD : 0}
                        formatValue={(value: any) => `$${nFormatter(value)}`}
                      />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LazyMotion features={domAnimation}>
          <m.div
            ref={towerRef}
            style={{ y, opacity }}
            className='max-w-[500px] md:max-w-[100%]'
          >
            <WrappedImg
              className={classNames(
                '-translate-x-[30%] -translate-y-[50%] scale-[1.8] sm:translate-x-16',
                'md:ml-auto md:h-[707px] md:translate-x-3 md:translate-y-0 md:scale-100'
              )}
              src={towerURL}
            />
          </m.div>
        </LazyMotion>
      </div>
    </section>
  );
}
