import {
    domAnimation,
    LazyMotion,
    m,
    useAnimationControls,
    useScroll,
    useSpring,
    useTransform,
} from 'framer-motion';
import { useContext, useEffect, useRef } from 'react';

import WrappedImg from '@/components/WrappedImg';

import { LoaderContext } from '@/contexts/LoaderContextProvider';

import { HomepageProps } from '../helpers/types';
import useBubbleData from '../helpers/useBubbleData';
import useCanAnimate from '../helpers/useCanAnimate';
import BubbleCard from './components/BubbleCard';

export default function BubbleCardList({ moveLess }: HomepageProps) {
  const controls = useAnimationControls();
  const { open } = useContext(LoaderContext);
  const tinyCardRef = useRef(null);
  const BUBBLE_CARDS = useBubbleData();
  const animate = useCanAnimate();

  const { scrollYProgress } = useScroll({
    target: tinyCardRef,
    offset: ['start end', 'end end'],
  });
  const SPRING_CONFIG = {
    stiffness: 400,
    damping: 70,
  };

  const x = useSpring(
      useTransform(scrollYProgress, [0, 1], [-200, 0]),
      SPRING_CONFIG,
    ),
    y = useSpring(
      useTransform(scrollYProgress, [0, 1], [200, 0]),
      SPRING_CONFIG,
    ),
    xR = useSpring(
      useTransform(scrollYProgress, [0, 1], [200, 0]),
      SPRING_CONFIG,
    ),
    opacity = useSpring(
      useTransform(scrollYProgress, [0, 0.5], [0, 1]),
      SPRING_CONFIG,
    );

  useEffect(() => {
    if (open) return;
    controls.start((i) => ({
      opacity: 1,
      transition: {
        ease: 'backInOut',
        duration: 0.5, // 800ms in order to wait somewhere animation
      },
    }));

    return controls.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <LazyMotion features={domAnimation}>
      <section className='container py-5'>
        <div className='space-y-7 max-w-[1196px] mx-auto'>
          {/* <WrappIf
            query={{ min: '1024px' }}
            props={{
              className: 'col-span-3 grid grid-cols-2 gap-4 xl:gap-7',
            }} 
          >*/}
          <div className='col-span-3 grid sm:grid-cols-2 gap-4 xl:gap-7'>
            {BUBBLE_CARDS.slice(0, 2).map((e) => (
              <m.div
                custom={e.id}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    ease: 'backInOut',
                    duration: 1,
                  },
                }}
                key={e.id}
              >
                <BubbleCard
                  {...e}
                  lens={
                    e?.lens && <WrappedImg src={`https://cdn.app.hatom.com/images/agora/${e.lens}`} />
                  }
                />
              </m.div>
            ))}
          </div>
          {/* </WrappIf> */}
          <div className='mx-auto grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:gap-7'>
            {BUBBLE_CARDS.slice(2).map((e, i) => (
              <LazyMotion features={domAnimation} key={i}>
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      ease: 'backInOut',
                      duration: 1,
                    },
                  }}
                  style={
                    moveLess
                      ? { opacity: 1 }
                      : animate
                      ? { ...[{ x }, { y }, { x: xR }][i], opacity }
                      : { opacity: 1 }
                  }
                  ref={tinyCardRef}
                >
                  <BubbleCard
                    {...e}
                    lens={
                      e?.lens && (
                        <WrappedImg src={`https://cdn.app.hatom.com/images/agora/${e.lens}.svg`} />
                      )
                    }
                  />
                </m.div>
              </LazyMotion>
            ))}
          </div>
        </div>
      </section>
    </LazyMotion>
  );
}
