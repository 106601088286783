import { useRouter } from 'next/router';
import React from 'react';

import Header from '@/components/Header';

export function EnhancedHeader() {
  const router = useRouter();
  return (
    <Header
      hiringTag
      actionButton={{
        label: 'Apps',
        onClick: () => {
          router.push('/lend');
        },
      }}
    />
  );
}
