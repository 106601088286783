/* eslint-disable no-console */
import classNames from 'classnames';
import { MotionConfig } from 'framer-motion';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import { isMobile } from 'react-device-detect';

import useSetBodyBg from '@/hooks/layout/useSetBodyBg';
import useLoader from '@/hooks/useLoader';

import DashboardFooter from '@/components/DashboardFooter';
import Meta from '@/components/Meta';

import { pageI18nNsMap } from '@/config/i18n';
import { preferRedirects } from '@/config/page';
import {
  BigCenteredTitle,
  BubbleCardList,
  EnhancedHeader,
  MarketSummary,
  NavigationCards,
  Opportunities,
  Python,
} from '@/sections/Homepage';

import { ROUTES } from '@/types/enums';

const Agora = () => {
  useSetBodyBg('#fff', '#0C0B21');
  useLoader();

  return (
    <MotionConfig
      transition={isMobile ? { duration: 0 } : undefined}
      reducedMotion={isMobile ? 'always' : 'never'}
    >
      <BigCenteredTitle moveLess={true} />
      <BubbleCardList moveLess={isMobile} />
      <Python moveLess={isMobile} />
      <Opportunities moveLess={isMobile} />
      <MarketSummary moveLess={isMobile} />
      <NavigationCards moveLess={isMobile} />
    </MotionConfig>
  );
};

Agora.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <div
      className={classNames(
        'Agora',
        'page-smooth-mode-transition',
        'bg-white text-[#06021F]',
        'dark:bg-[#0C0B21] dark:text-white',
        'font-ttFirsNeue font-semibold leading-tight',
        'w-screen overflow-hidden',
      )}
    >
      <Meta title='DeFi Hub' />
      <div className='container-lg relative z-50'>
        <EnhancedHeader />
      </div>
      <main className='isolate'>{page}</main>
      <div className='mt-20'>
        <DashboardFooter
          inheritTheme
          separator='#1A183F'
          className='text-[#06021F] dark:text-[#49477C]'
        />
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = preferRedirects(
  async (context) => {
    return {
      props: {
        ...(await serverSideTranslations(context.locale ?? 'en', [
          'translation',
          ...(pageI18nNsMap[ROUTES.HOME] ?? []),
        ])),
      },
    };
  },
);

export default Agora;
