import classNames from 'classnames';

import ChevronRightIcon from '@/sections/Homepage/Icons/ChevronRightIcon';

type SimpleButtonProps = {
  link?: boolean;
  secondary?: boolean;
  children: React.ReactNode;
};

export default function SimpleButton({
  link = false,
  secondary,
  children,
}: SimpleButtonProps) {
  const primary = !secondary;
  return (
    <button
      className={classNames(
        'flex items-center gap-2 whitespace-nowrap rounded-lg py-3 px-3 font-hass text-superxs capitalize leading-tight transition-colors lg:rounded-2xl lg:px-5 lg:text-sm lg:tracking-wide xl:py-4 xl:px-8',
        {
          'bg-[#346DF2] text-white hover:bg-[#346DF2]/80': primary,
          'bg-[#F4F5FA] text-[#346DF2] hover:bg-[#e8eaf3] dark:bg-[#343160] hover:dark:bg-[#3e3a6d]':
            secondary,
        }
      )}
    >
      {children} {link && <ChevronRightIcon />}
    </button>
  );
}
