import classNames from 'classnames';
import React from 'react';

import useToggle from '@/hooks/useToggle';

import CustomLink from '@/components/CustomLink';
import Hint from '@/components/Hint';

import useHintProps from '@/sections/Homepage/helpers/useHintProps';

import OpportunityBed from './OpportunityBed';

type OpportunityProps = {
  illustration: any;
  url?: string | undefined;
  textContent?: any;
  theme?: string[];
  style?: any;
  className?: any;
};

export default function Opportunity({
  illustration,
  url,
  textContent = [
    'Hatom Protocol',
    '$450M TVL',
    'The first lending protocol on the MultiversX Blockchain.',
  ],
  theme = [
    '#7343FB',
    'linear-gradient(99.1deg, #4927AB 1.6%, #0B0636 98.03%)',
    '#7857E3',
  ],
  style,
  className,
}: OpportunityProps) {
  const [title, subtitle, description] = textContent;
  const [borderColor, background, muted] = theme;
  const [hoverOn, toggle] = useToggle();
  const [clicked, , setClick] = useToggle();

  const hintProps = useHintProps({
    unvisible: !(!url && clicked && hoverOn),
    placement: 'top-center',
    x: 0,
    y: 34,
  });

  return (
    <Hint {...hintProps}>
      <div
        onClick={() => {
          if (!clicked) setClick(true);
        }}
        onMouseEnter={toggle}
        onMouseLeave={() => {
          toggle();
          setClick(false);
        }}
        className={classNames(
          'transition-transform duration-300 hover:-translate-y-1 hover:scale-[1.02]'
        )}
      >
        <CustomLink href={url} preventDefault={!url}>
          <OpportunityBed
            {...{
              background,
              borderColor,
            }}
          >
            <h3 className='text-base leading-tight xs:text-[20px]'>{title}</h3>
            <small className='relative z-50 text-[14px] xs:text-lg xs:leading-tight lg:max-w-[200px] xl:max-w-[100%]'>
              {subtitle}
            </small>
            <p
              style={{ color: muted, ...(style?.p ?? {}) }}
              className={classNames(
                className?.p,
                'relative z-50 max-w-[174px] text-xs xs:max-w-[218px] xs:text-sm xs:leading-tight sm:max-w-[174px] md:max-w-[230px]'
              )}
            >
              {description}
            </p>
            {React.cloneElement(illustration, {
              className: classNames(
                illustration.props.className,
                'scale-[.85] xs:scale-100 sm:scale-[.7] md:scale-[.9] lg:scale-[.8] xl:scale-100 origin-bottom-right'
              ),
            })}
          </OpportunityBed>
        </CustomLink>
      </div>
    </Hint>
  );
}
