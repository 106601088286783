import { motionValue, useSpring, useTransform } from 'framer-motion';
import { useEffect, useRef } from 'react';

type OptimizedPythonProps = {
  scrollYProgress: any;
  springConfig: any;
  className: string;
  dontAnimate: boolean | undefined;
};

export default function OptimizedPython({
  scrollYProgress,
  springConfig,
  className,
  dontAnimate,
}: OptimizedPythonProps) {
  const path = useRef<HTMLElement[]>([]);

  const strokeDasharray = useSpring(
    useTransform(
      dontAnimate ? motionValue(1) : scrollYProgress,
      [0, 1],
      [5200, 1900]
    ),
    springConfig
  );

  useEffect(() => {
    if (!path.current.length) return;
    strokeDasharray.onChange((e) => {
      for (const p of path.current) p.style.strokeDasharray = `3080 ${e}`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!path.current.length || dontAnimate) return;
    for (const p of path.current) {
      p.style.strokeDashoffset = '-4935';
      p.style.strokeDasharray = `3080 5200`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, path.current, dontAnimate]);

  return (
    <svg
      className={className}
      width={1550}
      height={513}
      viewBox='0 0 1550 513'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        ref={(ref: any) => {
          if (ref) path.current.push(ref);
        }}
        d='M1530.35 178.284C1207 128.398 1002 291.898 1068.5 398.398C1194.5 569.851 1447 128.398 1293.5 40.8983C1140 -46.6017 828.001 504.898 717.001 487.398C606.002 469.898 772.001 269.898 653.501 243.399C535.001 216.899 459.001 453.398 318.501 434.399C253.22 425.571 210.507 356.667 157.001 349.398C95.35 341.023 17.5 394.5 17.5 394.5'
        stroke='url(#paint0_linear_408_1720)'
        strokeWidth={34}
        strokeLinecap='round'
      />
      <path
        ref={(ref: any) => {
          if (ref) path.current.push(ref);
        }}
        d='M1534 171.353C1210.66 121.467 1004 279.352 1072.51 390.399C1198.51 561.852 1432 119.853 1292.5 33.8531C1139 -53.6469 821.498 512.953 721.501 497C614.998 487.453 783.004 264.589 654.504 235.853C536.004 209.353 448.004 445.595 320.504 428.353C255.223 419.525 210.506 358.269 157 351C134.166 347.549 86.5 359.5 19 402'
        stroke='url(#paint1_linear_408_1720)'
        strokeOpacity='0.47'
        strokeWidth={19}
        strokeLinecap='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_408_1720'
          x1='411.004'
          y1='172.909'
          x2='1553.5'
          y2='190.409'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3C52A9' />
          <stop offset='0.597627' stopColor='#2A8C75' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_408_1720'
          x1='415.003'
          y1='164.911'
          x2='1557.5'
          y2='182.411'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#364995' />
          <stop offset={1} stopColor='#004A39' stopOpacity='0.23' />
        </linearGradient>
      </defs>
    </svg>
  );
}
