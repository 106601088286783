import { motionValue, useSpring, useTransform } from 'framer-motion';
import { useEffect, useRef } from 'react';

type ChromePythonProps = {
  scrollYProgress: any;
  springConfig: any;
  className: string;
  dontAnimate: boolean | undefined;
};

export default function ChromePython({
  scrollYProgress,
  springConfig,
  className,
  dontAnimate,
}: ChromePythonProps) {
  const path = useRef(null);

  const strokeDasharray = useSpring(
    useTransform(
      dontAnimate ? motionValue(1) : scrollYProgress,
      [0, 1],
      [5200, 1900]
    ),
    springConfig
  );

  useEffect(() => {
    if (!path.current) return;
    strokeDasharray.onChange((e) => {
      if (path.current) {
        const pathElement = path.current as SVGPathElement | null;
        if (pathElement) pathElement.style.strokeDasharray = `3080 ${e}`;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!path.current || dontAnimate) return;
    if (path.current) {
      const pathElement = path.current as SVGPathElement | null;
      if (pathElement) {
        pathElement.style.strokeDashoffset = `-4935`;
        pathElement.style.strokeDasharray = `3080 5200`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, path.current, dontAnimate]);

  return (
    <svg
      className={className}
      width={1550}
      height={513}
      viewBox='0 0 1550 513'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_370_19437)'>
        <g filter='url(#filter0_i_370_19437)'>
          <path
            ref={path}
            d='M1531.35 176.735C1208 126.85 1003 290.35 1069.5 396.849C1195.5 568.302 1448 126.85 1294.5 39.3496C1141 -48.1504 829 503.35 718 485.849C607.001 468.349 773 268.35 654.5 241.85C536 215.35 460 451.85 319.5 432.85C254.219 424.022 211.506 355.119 158 347.85C96.3489 339.474 17.9999 393.35 17.9999 393.35'
            stroke='url(#paint0_linear_370_19437)'
            strokeWidth={34}
            strokeLinecap='round'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_i_370_19437'
          x='0.998047'
          y='12.9352'
          width='1568.35'
          height='494.324'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={22} dy={4} />
          <feGaussianBlur stdDeviation='10.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.36 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_370_19437'
          />
        </filter>
        <linearGradient
          id='paint0_linear_370_19437'
          x1={412}
          y1='171.36'
          x2='1554.5'
          y2='188.861'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3C52A9' />
          <stop offset='0.597627' stopColor='#2A8C75' />
        </linearGradient>
        <clipPath id='clip0_370_19437'>
          <rect
            width={1550}
            height={512}
            fill='white'
            transform='translate(0 0.770508)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
