import useTheme from '@/hooks/useTheme';

import WrappedImg from '@/components/WrappedImg';

export default function AppPrototype({ mobile = false }) {
  const { isDark } = useTheme();

  return mobile ? (
    <WrappedImg src='https://cdn.app.hatom.com/images/agora/app-prototype-xs.png' />
  ) : (
    <svg
      width='807'
      height='567'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 807 567'
    >
      <path
        stroke='url(#a)'
        strokeWidth='2'
        d='M780 1H13C6.37258 1 1 6.37257 1 13v503'
      />
      <path stroke='url(#b)' strokeWidth='2' d='M2.01562 59.2705H806.031' />
      <path stroke='url(#c)' strokeWidth='2' d='M1.02344 117.771H805.039' />
      <path stroke='url(#d)' strokeWidth='2' d='M71.0234 299.771H666.023' />
      <circle cx='28.5234' cy='31.2705' r='4.5' fill='#2645D3' />
      <circle cx='43.5234' cy='31.2705' r='4.5' fill='#E4C0F6' />
      <circle
        cx='58.5234'
        cy='31.2705'
        r='4.5'
        fill={isDark ? '#fff' : '#06021F'}
      />
      <path
        fill='#2C2A65'
        d='M42.7361 79.775c-.1329-.1404.685 3.0694-.6093 4.5558-3.2046 3.4884-5.854 1.4973-7.5384 6.3927-.0392.1143-.1938-1.4641-1.9797-2.8656-1.491-1.1699-4.5402-2.0193-5.6346-3.5271-1.2733-1.7537-.4481-4.634-.533-4.5558-1.2176 1.1178-2.7413 2.9509-2.3572 5.8653.5065 3.8431 4.0743 3.7977 5.8597 6.0385 3.0344 3.8078 1.8055 7.1769 4.6448 7.9358.7773-.0588 1.663-1.0132 2.132-2.4248.4939-1.4876.7782-3.1131 2.2083-5.1438 1.6274-2.3104 3.7303-2.2999 5.0258-3.6741 2.136-2.2656 1.7624-5.4481-1.2184-8.5969Z'
      />
      <g
        stroke='#201F43'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        clipPath='url(#e)'
      >
        <path d='m93.0234 85.7705 7.9996-4 8 4m-15.9996 0 7.9996 4 8-4m-15.9996 4 7.9996 4 8-4m-15.9996 4 7.9996 4 8-4' />
      </g>
      <g
        stroke='#201F43'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        clipPath='url(#f)'
      >
        <path d='m144.023 85.7705-2-4h-5l-2 4 2 4h5l2-4Z' />
        <path d='m151.023 89.7705-2-4h-5l-2 4 2 4h5l2-4Z' />
        <path d='m144.023 93.7705-2-4h-5l-2 4 2 4h5l2-4Z' />
      </g>
      <path
        stroke='#201F43'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M183.023 96.7705s-7-1-7-14l7-2 7 2c0 13-7 14-7 14Z'
      />
      <rect
        width='77'
        height='20'
        x='587.023'
        y='78.7705'
        stroke='url(#g)'
        strokeWidth='2'
        rx='7'
      />
      <rect
        width='77'
        height='20'
        x='490.023'
        y='78.7705'
        stroke='#201F43'
        strokeWidth='2'
        rx='7'
      />
      <rect
        width='36'
        height='3'
        x='516.023'
        y='87.7705'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='36'
        height='3'
        x='609.023'
        y='87.7705'
        fill='url(#h)'
        rx='1.5'
      />
      <circle cx='505.023' cy='88.7705' r='5' fill='#201F43' />
      <circle
        cx='121.023'
        cy='198.771'
        r='49'
        stroke='#201F43'
        strokeWidth='2'
      />
      <circle
        cx='350.523'
        cy='201.271'
        r='41.65'
        stroke='#201F43'
        strokeWidth='1.7'
      />
      <rect
        width='103'
        height='103'
        x='299.023'
        y='149.771'
        stroke='#201F43'
        strokeWidth='2'
        rx='51.5'
      />
      <circle
        cx='580.023'
        cy='198.771'
        r='49'
        stroke='#201F43'
        strokeWidth='2'
      />
      <rect
        width='36'
        height='3'
        x='103.023'
        y='184.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='111.023'
        y='202.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='36'
        height='3'
        x='332.023'
        y='189.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='340.023'
        y='207.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='36'
        height='3'
        x='562.023'
        y='184.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='570.023'
        y='202.771'
        fill='#201F43'
        rx='1.5'
      />
      <path
        stroke='#201F43'
        strokeLinecap='round'
        strokeWidth='2'
        d='M169.522 206.771s17.499 21.999 35 18.999c17.5-3 25.5-23 46.5-27.5s47.5 5.5 47.5 5.5m103.002-13.499s19-14.345 38-5.5c19 8.844 28 38.63 54 37.5 26-1.131 38-30 38-30'
      />
      <rect
        width='19'
        height='3'
        x='71.0234'
        y='290.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='71.0234'
        y='305.771'
        fill='#201F43'
        rx='1.5'
      />
      <path stroke='url(#i)' strokeWidth='2' d='M71.0234 390.771H346.523' />
      <rect
        width='38'
        height='3'
        x='101.023'
        y='366.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='92.0234'
        y='408.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='174.023'
        y='408.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='234.023'
        y='408.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='294.023'
        y='408.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='142.023'
        y='366.771'
        fill='#201F43'
        rx='1.5'
      />
      <path
        stroke='url(#j)'
        strokeWidth='2'
        d='M347.023 566.771v-208c0-11.046-8.954-20-20-20H91.0234c-11.0457 0-20 8.954-20 20v208'
      />
      <rect
        width='19'
        height='3'
        x='123.022'
        y='441.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='11'
        height='3'
        x='124.022'
        y='451.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='193.022'
        y='441.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='253.022'
        y='441.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='313.022'
        y='441.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='20.3462'
        height='21.2308'
        x='94.3495'
        y='437.655'
        stroke='#201F43'
        strokeWidth='1.76923'
        rx='7.96154'
      />
      <g opacity='.6'>
        <rect
          width='19'
          height='3'
          x='123.022'
          y='485.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='11'
          height='3'
          x='124.022'
          y='495.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='193.022'
          y='485.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='253.022'
          y='485.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='313.022'
          y='485.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='20.3462'
          height='21.2308'
          x='94.3495'
          y='481.655'
          stroke='#201F43'
          strokeWidth='1.76923'
          rx='7.96154'
        />
      </g>
      <g opacity='.3'>
        <rect
          width='19'
          height='3'
          x='123.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='11'
          height='3'
          x='124.022'
          y='539.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='193.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='253.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='313.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='20.3462'
          height='21.2308'
          x='94.3495'
          y='525.655'
          stroke='#201F43'
          strokeWidth='1.76923'
          rx='7.96154'
        />
      </g>
      <path stroke='url(#k)' strokeWidth='2' d='M387.023 390.771h275.5' />
      <rect
        width='38'
        height='3'
        x='417.023'
        y='366.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='408.023'
        y='408.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='490.023'
        y='408.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='550.023'
        y='408.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='38'
        height='3'
        x='610.023'
        y='408.771'
        fill='#201F43'
        opacity='.2'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='458.023'
        y='366.771'
        fill='#201F43'
        rx='1.5'
      />
      <path
        stroke='url(#l)'
        strokeWidth='2'
        d='M663.023 566.771v-208c0-11.046-8.954-20-20-20h-236c-11.045 0-20 8.954-20 20v208'
      />
      <rect
        width='19'
        height='3'
        x='439.022'
        y='441.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='11'
        height='3'
        x='440.022'
        y='451.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='509.022'
        y='441.771'
        fill='#201F43'
        rx='1.5'
      />
      <rect
        width='19'
        height='3'
        x='569.022'
        y='441.771'
        fill='#201F43'
        rx='1.5'
      />
      <path
        fill='#201F43'
        d='M629.022 443.271c0-.829.672-1.5 1.5-1.5h16c.829 0 1.5.671 1.5 1.5 0 .828-.671 1.5-1.5 1.5h-16c-.828 0-1.5-.672-1.5-1.5Z'
        opacity='.2'
      />
      <rect
        width='20.3462'
        height='21.2308'
        x='410.349'
        y='437.655'
        stroke='#201F43'
        strokeWidth='1.76923'
        rx='7.96154'
      />
      <g opacity='.6'>
        <rect
          width='19'
          height='3'
          x='439.022'
          y='485.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='11'
          height='3'
          x='440.022'
          y='495.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='509.022'
          y='485.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='569.022'
          y='485.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='629.022'
          y='485.771'
          fill='#201F43'
          opacity='.2'
          rx='1.5'
        />
        <rect
          width='20.3462'
          height='21.2308'
          x='410.349'
          y='481.655'
          stroke='#201F43'
          strokeWidth='1.76923'
          rx='7.96154'
        />
      </g>
      <g opacity='.3'>
        <rect
          width='19'
          height='3'
          x='439.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='11'
          height='3'
          x='440.022'
          y='539.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='509.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='569.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='19'
          height='3'
          x='629.022'
          y='529.771'
          fill='#201F43'
          rx='1.5'
        />
        <rect
          width='20.3462'
          height='21.2308'
          x='410.349'
          y='525.655'
          stroke='#201F43'
          strokeWidth='1.76923'
          rx='7.96154'
        />
      </g>
      <defs>
        <linearGradient
          id='a'
          x1='51.5'
          x2='329'
          y1='-14.5'
          y2='453.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#201F43' />
          <stop offset='.80426' stopColor='#201F43' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='b'
          x1='806.023'
          x2='2.02348'
          y1='60.7741'
          y2='60.7714'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.18228' stopColor='#201F43' stopOpacity='0' />
          <stop offset='.34896' stopColor='#201F43' />
        </linearGradient>
        <linearGradient
          id='c'
          x1='805.031'
          x2='1.0313'
          y1='119.274'
          y2='119.271'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.18228' stopColor='#282647' stopOpacity='0' />
          <stop offset='.35938' stopColor='#201F43' />
        </linearGradient>
        <linearGradient
          id='d'
          x1='874.924'
          x2='70.9238'
          y1='123967000000000000'
          y2='123967000000000000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.26455' stopColor='#201F43' stopOpacity='0' />
          <stop offset='.4464' stopColor='#201F43' />
        </linearGradient>
        <linearGradient
          id='g'
          x1='586.023'
          x2='672.023'
          y1='99.7705'
          y2='99.7705'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#201F43' />
          <stop offset='1' stopColor='#201F43' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='h'
          x1='604.523'
          x2='646.023'
          y1='92.7705'
          y2='92.2705'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#201F43' />
          <stop offset='1' stopColor='#201F43' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='i'
          x1='443.192'
          x2='70.9195'
          y1='123967000000000000'
          y2='123967000000000000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.18228' stopColor='#201F43' stopOpacity='0' />
          <stop offset='.35938' stopColor='#201F43' />
        </linearGradient>
        <linearGradient
          id='j'
          x1='209.023'
          x2='209.023'
          y1='313.771'
          y2='566.77'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#201F43' />
          <stop offset='.79695' stopColor='#201F43' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='k'
          x1='759.192'
          x2='386.92'
          y1='123967000000000000'
          y2='123967000000000000'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='.24479' stopColor='#282647' stopOpacity='0' />
          <stop offset='.75521' stopColor='#201F43' />
        </linearGradient>
        <linearGradient
          id='l'
          x1='416.523'
          x2='597.523'
          y1='350.771'
          y2='519.771'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#201F43' />
          <stop offset='.79695' stopColor='#201F43' stopOpacity='0' />
        </linearGradient>
        <clipPath id='e'>
          <path fill='#fff' d='M92.0234 80.7705h18v18h-18z' />
        </clipPath>
        <clipPath id='f'>
          <path fill='#fff' d='M134.023 80.7705h18v18h-18z' />
        </clipPath>
      </defs>
    </svg>
  );
}
