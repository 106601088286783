import classNames from 'classnames';
import {
    domAnimation,
    LazyMotion,
    m,
    motionValue,
    useScroll,
    useSpring,
    useTransform,
} from 'framer-motion';
import { useRef } from 'react';

import useTheme from '@/hooks/useTheme';

import { slugify } from '@/utils/helpers';

import { HomepageProps } from '../helpers/types';
import useLinksData from '../helpers/useLinksData';
import BlurryShadow from '../PythonPath/components/BlurryShadow';
import SquareLink from './components/SquareLink';

export default function NavigationCards({ moveLess }: HomepageProps) {
  const { isLight } = useTheme();

  const LINKS = useLinksData();

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', '300px end'],
  });
  const SPRING_CONFIG = {
    stiffness: 400,
    damping: 80,
  };

  return (
    <section ref={ref} className='container-lg relative py-5'>
      <BlurryShadow
        className={classNames(
          'absolute bottom-0 right-0 -z-20 ',
          'translate-x-[120%] translate-y-[10%]',
          'h-[1512.96px] w-[687.4px]'
        )}
        background={
          isLight
            ? 'radial-gradient(50% 50% at 50% 50%, rgba(163, 103, 247, 0.62) 0%, rgba(255, 255, 255, 0) 63.54%)'
            : 'radial-gradient(50% 50% at 50% 50%, rgba(35, 17, 60, 0.62) 0%, rgba(56, 33, 121, 0) 63.54%)'
        }
        rotateDeg={55}
      />
      <BlurryShadow
        className={classNames(
          'absolute bottom-0 right-0 -z-20',
          'translate-x-[96%] translate-y-[48%]',
          'h-[885.79px] w-[402.45px]'
        )}
        background={
          isLight
            ? 'radial-gradient(50% 50% at 50% 50%, rgba(74, 234, 234, 0.3) 0%, rgba(71, 205, 181, 0) 63.54%)'
            : 'radial-gradient(50% 50% at 50% 50%, rgba(68, 176, 176, 0.3) 0%, rgba(56, 33, 121, 0) 63.54%)'
        }
        rotateDeg={55}
      />
      <div className='mt-10 grid gap-8 sm:grid-cols-2 lg:grid-cols-4 lg:gap-5'>
        {LINKS.map((e, i) => {
          const indexPlus = i + 1,
            reversed = i >= 2,
            firstOrLast = i == 0 || i === LINKS.length - 1,
            occupation = 0.6,
            initialX = -80,
            targetX = initialX * indexPlus * (reversed ? -1 : 1),
            x = useSpring(
              useTransform(
                moveLess ? motionValue(1) : scrollYProgress,
                [
                  [0, occupation],
                  [1 - occupation, 1],
                  [1 - occupation, 1],
                  [0, occupation],
                ][i],
                [targetX, 0]
              ),
              SPRING_CONFIG
            ),
            opacity = useSpring(
              useTransform(scrollYProgress, [1 - occupation, 1], [0, 1]),
              SPRING_CONFIG
            );

          return (
            <LazyMotion key={i} features={domAnimation}>
              <m.div
                style={{
                  x,
                  zIndex: firstOrLast ? 10 : 1,
                  opacity: firstOrLast ? 1 : opacity,
                }}
              >
                <SquareLink name={e.gropuName} href={e.url}>
                  <img
                    src={`https://cdn.app.hatom.com/images/agora/${slugify(e.slug)}.svg`}
                    alt={e.body}
                  />
                  {e.body}
                </SquareLink>
              </m.div>
            </LazyMotion>
          );
        })}
      </div>
    </section>
  );
}
