import classNames from 'classnames';

export default function BlurryShadow({
  className = '',
  background = 'radial-gradient(50% 50% at 50% 50%, rgba(74, 234, 234, 0.3) 0%, rgba(71, 205, 181, 0) 63.54%)',
  rotateDeg = 0,
  style = {},
}) {
  return (
    <div
      className={classNames(className)}
      style={{
        ...style,
        background,
        rotate: `${rotateDeg}deg`,
      }}
    ></div>
  );
}
